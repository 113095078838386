export function setMessageAction (newValue) {
    if (this.debug) {
        console.log('setMessageAction triggered with', newValue)
    }
    this.api().request(newValue)
    this.state.message = newValue
}

export function clearMessageAction() {
    if (this.debug) {
        console.log('clearMessageAction triggered')
    }
    this.state.auth.loggedIn = false
    this.state.message = ''
}

export const authState = {
    loggedIn: true,
    token: 'ayJ0DXAiWiJKV1QiLCJhbGciOiJS',
    refreshToken: null
}

