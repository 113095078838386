import {reactive} from 'vue'
import {api} from "./api";
import {authState} from "./controllers/auth";
import {setMessageAction, clearMessageAction} from "./controllers/events";


export default {
    debug: true,

    api: api,

    state: reactive({
        auth: authState,
        message: 'Hello!'
    }),

    setMessageAction: setMessageAction,

    clearMessageAction: clearMessageAction
}
