import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import Store from "./store/store";

import './assets/styles/tailwind.css';

const app = createApp(App)

app.config.globalProperties.foo = 'bar'
app.config.globalProperties.$store = Store

app.config.globalProperties.$env = reactive({
    sidebarIsOpen: false
})

app.use(router)
app.mount('#app')
