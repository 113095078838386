export function api() {
    const api = {}
    const url = 'http://localhost:4100'
    api.login = () => {
    },
        api.request = async (payload) => {
            console.log(payload)
            console.log(url)
            console.log(this.debug)
        }
    api.getRequest = async (path = null, callback = data => data) => {
        await fetch(url + path, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ayJ0DXAiWiJKV1QiLCJhbGciOiJS'
            })
        })
            .then(res => {
                if (res.ok) {
                    return res
                }
                throw new Error('Network response was not ok. Status: ' + res.status);
            })
            .then(res => res.json())
            .then(callback)
            .catch(function (error) {
                console.log('There has been a problem with your fetch operation: ', error.message)
            })
    }
    api.postRequest = async (path = null, data = {}, callback = data => data) => {
        await fetch(url + path, {
            method: 'POST',
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ayJ0DXAiWiJKV1QiLCJhbGciOiJS'
            }),
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    return res
                }
                throw new Error('Network response was not ok. Status: ' + res.status);
            })
            .then(res => res.json())
            .then(callback)
            .catch(function (error) {
                console.log('There has been a problem with your fetch operation: ', error.message)
            })
        // this.event = this.events.filter(event => event.id == id)[0]
    }
    return api
}
