<template>
  <router-view />
</template>

<style>

</style>
<style lang="scss">

 @import "./assets/styles/tailwind.css";

  @import './scss/bootstrap.scss';

</style>
