import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    children: [
      {
        path: 'events',
        name: 'AdminEvents',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "adminEvents" */ '../views/Admin/Events.vue'),
        meta: {sidebar: 'events'}
      },{
        path: 'event/:id',
        name: 'AdminEvent',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "adminEvent" */ '../views/Admin/Event.vue'),
        meta: {sidebar: 'event'},
        children: [
          {
            path: '',
            name: 'AdminEventDashboard',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "adminEventDashboard" */ '../views/Admin/Event/Dashboard.vue')
          },{
            path: 'settings',
            name: 'AdminEventSettings',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "adminEventSettings" */ '../views/Admin/Event/Settings.vue')
          },{
            path: 'mailings',
            name: 'AdminEventMailings',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "adminEventMailings" */ '../views/Admin/Event/Mailings.vue')
          },{
            path: 'landingpage',
            name: 'AdminEventLandingpage',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "adminEventLandingpage" */ '../views/Admin/Event/Landingpage.vue')
          },{
            path: 'attendees',
            name: 'AdminEventAttendees',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "adminEventAttendees" */ '../views/Admin/Event/Attendees.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/lp',
    name: 'Landingpage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "landingpage" */ '../views/Landingpage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
